<script setup lang="ts">
import { useCategoryStore } from '~~/stores/category'

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
  }>(),
  {
    modelValue: false
  }
)
const { modelValue } = toRefs(props)

const categoryStore = useCategoryStore()
categoryStore.fetchCategories()

const categories = computed(() => categoryStore.state.categories)
</script>

<template>
  <div class="kg kg-menu-sub active">
    <a-row :gutter="[80, 16]">
      <template v-if="categories?.data?.length">
        <a-col
          v-for="(category, index) in categories?.data"
          :key="index"
          :span="12"
        >
          <a :href="`/kategori?search=${category.serial}`">
            {{ category.name }}
          </a>
        </a-col>
      </template>
      <a-col v-else-if="categories?.isLoading" class="mt-4 mx-auto">
        <a-spin />
      </a-col>
      <a-col v-else :span="24" class="mt-6">
        <AtomsEmpty height="100px" description="Kategori tidak ditemukan" />
      </a-col>
    </a-row>
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
