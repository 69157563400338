<script setup lang="ts">
const emit = defineEmits(['hide'])
</script>

<template>
  <div id="searchbar-mobile" class="searchbar-mobile">
    <div
      class="mr-2 text-2xl cursor-pointer"
      :style="{ lineHeight: 0 }"
      @click="emit('hide')"
    >
      <arrow-left-outlined />
    </div>
    <MoleculesSearchBar class="w-full" />
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
