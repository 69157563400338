import { defineStore } from 'pinia'

import { useMessageStore } from '../message'
import { type CategoryState } from 'types/category'

export const useCategoryStore = defineStore('category', () => {
  const state = reactive<CategoryState>({
    categories: {
      data: [],
      isLoading: false
    }
  })

  const fetchCategories = async () => {
    const messageStore = useMessageStore()
    const { API_URL } = useBaseUrl()

    state.categories.isLoading = true
    const { data, error } = await useAsyncData('categories', () =>
      $fetch(`${API_URL}/categories`, {
        method: 'GET'
      })
    )
    state.categories.isLoading = false

    if (!data.value && error.value) {
      const statusCode: number = useResult(error.value, 'data.code')
      messageStore.notifyStdError(statusCode)
      throw createError({
        statusCode,
        statusMessage: `fetchCategories: ${error.value?.message}`
      })
    } else {
      state.categories.data = useResult(data?.value, 'data', [])
    }
  }

  return {
    state,
    fetchCategories
  }
})
