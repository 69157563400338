<script setup lang="ts">
const showSearchbarMobile = ref<boolean>(false)
const showSidebar = ref<boolean>(false)
const sidebarRef = ref(null)

useClickOutside(sidebarRef, () => {
  showSidebar.value = false
})

const onShowSidebar = () => {
  showSidebar.value = true
}
const onCloseSidebar = () => {
  showSidebar.value = false
}

defineEmits(['showSignin'])
</script>

<template>
  <div class="kg-navbar-mobile">
    <div ref="sidebarRef">
      <div class="text-2xl cursor-pointer contents" @click="onShowSidebar">
        <menu-outlined />
      </div>
      <MoleculesSidebar
        :show-sidebar="showSidebar"
        @close-sidebar="onCloseSidebar"
        @show-signin="$emit('showSignin')"
      />
    </div>
    <div>
      <NuxtLink to="/" class="kg-navbar__logo">
        <nuxt-img
          src="/images/logo-kognisi-mobile.png"
          alt="logo-kognisi"
          loading="lazy"
          size="sm:100%"
        />
      </NuxtLink>
    </div>
    <div>
      <div class="text-2xl cursor-pointer" @click="showSearchbarMobile = true">
        <search-outlined />
      </div>
      <MoleculesSearchBarMobile
        v-if="showSearchbarMobile"
        @hide="showSearchbarMobile = false"
      />
    </div>
  </div>
  <AtomsOverlay :show-sidebar="showSidebar" :is-active="showSidebar" />
</template>

<style lang="scss">
@import './styles.scss';
</style>
